import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import LogoAlt from "../images/logo_alt_white.png"
import GregSrc from "../images/greg.jpg"
import JeddSrc from "../images/jedd.jpg"
import SteveFSrc from "../images/stevef.jpg"

const About = () => (
  <Layout pageName="about">
    <SEO title="About Us" />
    <h1>
      <span>Beers On Rotation</span>
    </h1>
    <div>
      <img src={LogoAlt} alt="Rotation Vinyl" />
      <div class="article">
        <h3>Brewing Locally, Inside the Beltway</h3>
        <p>
          Rotation Beer, members of the Alexandria Society of Homebrewers, is
          bringing new and interesting beers to the Northern Virginia area.
        </p>
        <p>
          With a focus on big, hoppy IPAs and drinkable, distinguished porters
          and lagers, Rotation Beer provides a high quality experience for every
          kind of beer drinker.
        </p>
        <h3>Homebrew Only... For Now</h3>
        <p>
          Currently our beer is homebrew only, so you can't find us in stores or
          restaurants. We're looking to change that soon though. If you're
          interested in trying our beers or getting in touch. Head on over to
          the <Link to="/contact">Contact</Link> page and hit us up.
        </p>
        <h3>Us</h3>
        <p>
          Barons of beer and brewing, masters of malt, head-honchos of hops.
        </p>
        <div className="gallery">
          <ul>
            <li className="grayscale">
              <img src={GregSrc} alt="Greg" />
              <span className="top-title">Greg</span>
            </li>
            <li className="grayscale">
              <img src={JeddSrc} alt="Jedd" />
              <span className="top-title">Jedd</span>
            </li>
            <li className="grayscale">
              <img src={SteveFSrc} alt="Steve" />
              <span className="top-title">Steve</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default About
